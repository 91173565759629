const metadata = {
  addType: ["sell", "rent"],
  assetType: ["apartment", "house"],
  buildingYear: {
    max: 2031.0,
    min: 1550.0,
  },
  characteristics: [
    "doubleWindows",
    "suite",
    "terrace",
    "garden",
    "balcony",
    "basement",
    "pool",
    "airConditioning",
    "closedGarage",
    "equippedKitchen",
    "elevator",
    "centralHeating",
    "goodLightExposure",
    "seaView",
    "parking",
  ],
  condition: ["new", "used", "renovated", "inConstruction", "forRecovery"],
  energyEfficiency: ["A+", "A", "B", "B-", "C", "D", "E", "F", "G", "NC"],
  grossArea: {
    max: 2000.0,
    min: 1.3200000524520874,
  },
  latitude: {
    max: 42.13999938964844,
    min: 32.632999420166016,
  },
  longitude: {
    max: -6.26170015335083,
    min: -31.263599395751953,
  },
  //   numberOfDivisions: {
  //     required: false,
  //     type: "integer",
  //     values: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  //   },
  numberOfParkingSpots: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  numberOfRooms: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  numberOfWcs: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
  terrainArea: {
    max: 1000000.0,
    min: 0.0,
  },
  usefulArea: {
    max: 2000.0,
    min: 1.100000023841858,
  },
  owner: [true, false],
  project: ["sell", "buy", "other"],
  timeframe: ["ongoing", "3months", "3to6months", "6monthsOrMore", "estimate"],
  areyou: ["buy", "professional", "other"],
  // type: ["buy", "sell", "professional", "visit"],
  sellProcess: ["start", "ongoing", "estimate"],
  alreadyTriedToSell: [false, true],
  listedInAgency: [false, true],
};

export default metadata;
